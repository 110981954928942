@font-face {
    font-family: "iconfont";
    src: url('/cache-buster-1739447530465/static/build/icons/font/iconfont.eot?#iefix') format('eot'),
    url('/cache-buster-1739447530465/static/build/icons/font/iconfont.woff') format('woff'),
    url('/cache-buster-1739447530465/static/build/icons/font/iconfont.ttf') format('ttf');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

.icon {
    display: inline-block;
    font-family: "iconfont";
    speak: none;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: translate(0, 0);
}

.icon-lg {
    font-size: 1.3333333333333333em;
    line-height: 0.75em;
    vertical-align: -15%;
}
.icon-2x { font-size: 2em; }
.icon-3x { font-size: 3em; }
.icon-4x { font-size: 4em; }
.icon-5x { font-size: 5em; }
.icon-fw {
    width: 1.2857142857142858em;
    text-align: center;
}

.icon-ul {
    padding-left: 0;
    margin-left: 2.14285714em;
    list-style-type: none;
}
.icon-ul > li {
    position: relative;
}
.icon-li {
    position: absolute;
    left: -2.14285714em;
    width: 2.14285714em;
    top: 0.14285714em;
    text-align: center;
}
.icon-li.icon-lg {
    left: -1.85714286em;
}

.icon-rotate-90 {
    transform: rotate(90deg);
}
.icon-rotate-180 {
    transform: rotate(180deg);
}
.icon-rotate-270 {
    transform: rotate(270deg);
}
.icon-flip-horizontal {
    transform: scale(-1, 1);
}
.icon-flip-vertical {
    transform: scale(1, -1);
}
:root .icon-rotate-90,
:root .icon-rotate-180,
:root .icon-rotate-270,
:root .icon-flip-horizontal,
:root .icon-flip-vertical {
  filter: none;
}

.icon-accomodation:before { content: "\EA01" }
.icon-addthis:before { content: "\EA02" }
.icon-animation:before { content: "\EA03" }
.icon-arrow-dropdown:before { content: "\EA04" }
.icon-arrow-left:before { content: "\EA05" }
.icon-arrow-long:before { content: "\EA06" }
.icon-arrow-right:before { content: "\EA07" }
.icon-benefit:before { content: "\EA08" }
.icon-bill-adress:before { content: "\EA09" }
.icon-bill:before { content: "\EA0A" }
.icon-binocular:before { content: "\EA0B" }
.icon-book:before { content: "\EA0C" }
.icon-boot:before { content: "\EA0D" }
.icon-bus:before { content: "\EA0E" }
.icon-cablecar:before { content: "\EA0F" }
.icon-calendar:before { content: "\EA10" }
.icon-camera:before { content: "\EA11" }
.icon-car:before { content: "\EA12" }
.icon-carnet-advantages:before { content: "\EA13" }
.icon-carpet:before { content: "\EA14" }
.icon-cart:before { content: "\EA15" }
.icon-chairlift-2:before { content: "\EA16" }
.icon-chairlift-3:before { content: "\EA17" }
.icon-chairlift-4:before { content: "\EA18" }
.icon-chairlift-5:before { content: "\EA19" }
.icon-chairlift-6:before { content: "\EA1A" }
.icon-chairlift-outline:before { content: "\EA1B" }
.icon-chairlift:before { content: "\EA1C" }
.icon-changingtable:before { content: "\EA1D" }
.icon-check:before { content: "\EA1E" }
.icon-climbing-outline:before { content: "\EA1F" }
.icon-clock:before { content: "\EA20" }
.icon-close:before { content: "\EA21" }
.icon-closed:before { content: "\EA22" }
.icon-cross-country-skiing:before { content: "\EA23" }
.icon-delete-outline:before { content: "\EA24" }
.icon-delete:before { content: "\EA25" }
.icon-delivery-adress:before { content: "\EA26" }
.icon-delivery:before { content: "\EA27" }
.icon-dog:before { content: "\EA28" }
.icon-download:before { content: "\EA29" }
.icon-echarging-station:before { content: "\EA2A" }
.icon-elements:before { content: "\EA2B" }
.icon-elevator:before { content: "\EA2C" }
.icon-envelope:before { content: "\EA2D" }
.icon-events:before { content: "\EA2E" }
.icon-exclamation-mark:before { content: "\EA2F" }
.icon-external-link:before { content: "\EA30" }
.icon-eye-outline:before { content: "\EA31" }
.icon-facebook:before { content: "\EA32" }
.icon-family:before { content: "\EA33" }
.icon-fast:before { content: "\EA34" }
.icon-fixed-rope-route:before { content: "\EA35" }
.icon-followers:before { content: "\EA36" }
.icon-forest:before { content: "\EA37" }
.icon-fun-zone:before { content: "\EA38" }
.icon-funispace:before { content: "\EA39" }
.icon-gates:before { content: "\EA3A" }
.icon-gondola-chairlift-combi:before { content: "\EA3B" }
.icon-gondola-fill:before { content: "\EA3C" }
.icon-gondola:before { content: "\EA3D" }
.icon-google:before { content: "\EA3E" }
.icon-group:before { content: "\EA3F" }
.icon-highchair:before { content: "\EA40" }
.icon-hiking:before { content: "\EA41" }
.icon-hotel:before { content: "\EA42" }
.icon-house:before { content: "\EA43" }
.icon-in-preparation:before { content: "\EA44" }
.icon-info:before { content: "\EA45" }
.icon-infopoint:before { content: "\EA46" }
.icon-instagram:before { content: "\EA47" }
.icon-jumbo:before { content: "\EA48" }
.icon-leisure-zone:before { content: "\EA49" }
.icon-lift:before { content: "\EA4A" }
.icon-linkedin:before { content: "\EA4B" }
.icon-lock:before { content: "\EA4C" }
.icon-magnifier:before { content: "\EA4D" }
.icon-mail:before { content: "\EA4E" }
.icon-map:before { content: "\EA4F" }
.icon-menue:before { content: "\EA50" }
.icon-milestone:before { content: "\EA51" }
.icon-money:before { content: "\EA52" }
.icon-mountain:before { content: "\EA53" }
.icon-nav-plus:before { content: "\EA54" }
.icon-news:before { content: "\EA55" }
.icon-open:before { content: "\EA56" }
.icon-panorama:before { content: "\EA57" }
.icon-panoramic-terrace:before { content: "\EA58" }
.icon-paragliding:before { content: "\EA59" }
.icon-pen-outline:before { content: "\EA5A" }
.icon-pen-paper:before { content: "\EA5B" }
.icon-phone:before { content: "\EA5C" }
.icon-pin:before { content: "\EA5D" }
.icon-pinterest:before { content: "\EA5E" }
.icon-plane:before { content: "\EA5F" }
.icon-play:before { content: "\EA60" }
.icon-playground-filled:before { content: "\EA61" }
.icon-playground:before { content: "\EA62" }
.icon-plus:before { content: "\EA63" }
.icon-printer:before { content: "\EA64" }
.icon-quote-mark:before { content: "\EA65" }
.icon-restaurant:before { content: "\EA66" }
.icon-road:before { content: "\EA67" }
.icon-sadbag:before { content: "\EA68" }
.icon-sale:before { content: "\EA69" }
.icon-scooter:before { content: "\EA6A" }
.icon-secure:before { content: "\EA6B" }
.icon-self-service:before { content: "\EA6C" }
.icon-service:before { content: "\EA6D" }
.icon-singletrail:before { content: "\EA6E" }
.icon-ski-touring:before { content: "\EA6F" }
.icon-ski:before { content: "\EA70" }
.icon-skier:before { content: "\EA71" }
.icon-skiing:before { content: "\EA72" }
.icon-sledge:before { content: "\EA73" }
.icon-slope-gradient:before { content: "\EA74" }
.icon-slope:before { content: "\EA75" }
.icon-slopes:before { content: "\EA76" }
.icon-snow:before { content: "\EA77" }
.icon-snowflake:before { content: "\EA78" }
.icon-snowpark:before { content: "\EA79" }
.icon-snowshoe-hiking:before { content: "\EA7A" }
.icon-sports-center:before { content: "\EA7B" }
.icon-star-filled:before { content: "\EA7C" }
.icon-star:before { content: "\EA7D" }
.icon-start-finish:before { content: "\EA7E" }
.icon-sun-rise:before { content: "\EA7F" }
.icon-sun:before { content: "\EA80" }
.icon-swimming:before { content: "\EA81" }
.icon-take-away:before { content: "\EA82" }
.icon-teleski:before { content: "\EA83" }
.icon-terrace:before { content: "\EA84" }
.icon-ticket:before { content: "\EA85" }
.icon-tickets-outline:before { content: "\EA86" }
.icon-tiktok:before { content: "\EA87" }
.icon-toboggan:before { content: "\EA88" }
.icon-train:before { content: "\EA89" }
.icon-twint:before { content: "\EA8A" }
.icon-twitter:before { content: "\EA8B" }
.icon-upload:before { content: "\EA8C" }
.icon-user:before { content: "\EA8D" }
.icon-vertigo-outline:before { content: "\EA8E" }
.icon-warning:before { content: "\EA8F" }
.icon-weather:before { content: "\EA90" }
.icon-webcam:before { content: "\EA91" }
.icon-wellness:before { content: "\EA92" }
.icon-whatsapp:before { content: "\EA93" }
.icon-wheelchair:before { content: "\EA94" }
.icon-wifi:before { content: "\EA95" }
.icon-xl:before { content: "\EA96" }
.icon-youtube:before { content: "\EA97" }
.icon-zipline-outline:before { content: "\EA98" }
.icon-zipline:before { content: "\EA99" }


:root {
--icon-accomodation: "\EA01";
    --icon-addthis: "\EA02";
    --icon-animation: "\EA03";
    --icon-arrow-dropdown: "\EA04";
    --icon-arrow-left: "\EA05";
    --icon-arrow-long: "\EA06";
    --icon-arrow-right: "\EA07";
    --icon-benefit: "\EA08";
    --icon-bill-adress: "\EA09";
    --icon-bill: "\EA0A";
    --icon-binocular: "\EA0B";
    --icon-book: "\EA0C";
    --icon-boot: "\EA0D";
    --icon-bus: "\EA0E";
    --icon-cablecar: "\EA0F";
    --icon-calendar: "\EA10";
    --icon-camera: "\EA11";
    --icon-car: "\EA12";
    --icon-carnet-advantages: "\EA13";
    --icon-carpet: "\EA14";
    --icon-cart: "\EA15";
    --icon-chairlift-2: "\EA16";
    --icon-chairlift-3: "\EA17";
    --icon-chairlift-4: "\EA18";
    --icon-chairlift-5: "\EA19";
    --icon-chairlift-6: "\EA1A";
    --icon-chairlift-outline: "\EA1B";
    --icon-chairlift: "\EA1C";
    --icon-changingtable: "\EA1D";
    --icon-check: "\EA1E";
    --icon-climbing-outline: "\EA1F";
    --icon-clock: "\EA20";
    --icon-close: "\EA21";
    --icon-closed: "\EA22";
    --icon-cross-country-skiing: "\EA23";
    --icon-delete-outline: "\EA24";
    --icon-delete: "\EA25";
    --icon-delivery-adress: "\EA26";
    --icon-delivery: "\EA27";
    --icon-dog: "\EA28";
    --icon-download: "\EA29";
    --icon-echarging-station: "\EA2A";
    --icon-elements: "\EA2B";
    --icon-elevator: "\EA2C";
    --icon-envelope: "\EA2D";
    --icon-events: "\EA2E";
    --icon-exclamation-mark: "\EA2F";
    --icon-external-link: "\EA30";
    --icon-eye-outline: "\EA31";
    --icon-facebook: "\EA32";
    --icon-family: "\EA33";
    --icon-fast: "\EA34";
    --icon-fixed-rope-route: "\EA35";
    --icon-followers: "\EA36";
    --icon-forest: "\EA37";
    --icon-fun-zone: "\EA38";
    --icon-funispace: "\EA39";
    --icon-gates: "\EA3A";
    --icon-gondola-chairlift-combi: "\EA3B";
    --icon-gondola-fill: "\EA3C";
    --icon-gondola: "\EA3D";
    --icon-google: "\EA3E";
    --icon-group: "\EA3F";
    --icon-highchair: "\EA40";
    --icon-hiking: "\EA41";
    --icon-hotel: "\EA42";
    --icon-house: "\EA43";
    --icon-in-preparation: "\EA44";
    --icon-info: "\EA45";
    --icon-infopoint: "\EA46";
    --icon-instagram: "\EA47";
    --icon-jumbo: "\EA48";
    --icon-leisure-zone: "\EA49";
    --icon-lift: "\EA4A";
    --icon-linkedin: "\EA4B";
    --icon-lock: "\EA4C";
    --icon-magnifier: "\EA4D";
    --icon-mail: "\EA4E";
    --icon-map: "\EA4F";
    --icon-menue: "\EA50";
    --icon-milestone: "\EA51";
    --icon-money: "\EA52";
    --icon-mountain: "\EA53";
    --icon-nav-plus: "\EA54";
    --icon-news: "\EA55";
    --icon-open: "\EA56";
    --icon-panorama: "\EA57";
    --icon-panoramic-terrace: "\EA58";
    --icon-paragliding: "\EA59";
    --icon-pen-outline: "\EA5A";
    --icon-pen-paper: "\EA5B";
    --icon-phone: "\EA5C";
    --icon-pin: "\EA5D";
    --icon-pinterest: "\EA5E";
    --icon-plane: "\EA5F";
    --icon-play: "\EA60";
    --icon-playground-filled: "\EA61";
    --icon-playground: "\EA62";
    --icon-plus: "\EA63";
    --icon-printer: "\EA64";
    --icon-quote-mark: "\EA65";
    --icon-restaurant: "\EA66";
    --icon-road: "\EA67";
    --icon-sadbag: "\EA68";
    --icon-sale: "\EA69";
    --icon-scooter: "\EA6A";
    --icon-secure: "\EA6B";
    --icon-self-service: "\EA6C";
    --icon-service: "\EA6D";
    --icon-singletrail: "\EA6E";
    --icon-ski-touring: "\EA6F";
    --icon-ski: "\EA70";
    --icon-skier: "\EA71";
    --icon-skiing: "\EA72";
    --icon-sledge: "\EA73";
    --icon-slope-gradient: "\EA74";
    --icon-slope: "\EA75";
    --icon-slopes: "\EA76";
    --icon-snow: "\EA77";
    --icon-snowflake: "\EA78";
    --icon-snowpark: "\EA79";
    --icon-snowshoe-hiking: "\EA7A";
    --icon-sports-center: "\EA7B";
    --icon-star-filled: "\EA7C";
    --icon-star: "\EA7D";
    --icon-start-finish: "\EA7E";
    --icon-sun-rise: "\EA7F";
    --icon-sun: "\EA80";
    --icon-swimming: "\EA81";
    --icon-take-away: "\EA82";
    --icon-teleski: "\EA83";
    --icon-terrace: "\EA84";
    --icon-ticket: "\EA85";
    --icon-tickets-outline: "\EA86";
    --icon-tiktok: "\EA87";
    --icon-toboggan: "\EA88";
    --icon-train: "\EA89";
    --icon-twint: "\EA8A";
    --icon-twitter: "\EA8B";
    --icon-upload: "\EA8C";
    --icon-user: "\EA8D";
    --icon-vertigo-outline: "\EA8E";
    --icon-warning: "\EA8F";
    --icon-weather: "\EA90";
    --icon-webcam: "\EA91";
    --icon-wellness: "\EA92";
    --icon-whatsapp: "\EA93";
    --icon-wheelchair: "\EA94";
    --icon-wifi: "\EA95";
    --icon-xl: "\EA96";
    --icon-youtube: "\EA97";
    --icon-zipline-outline: "\EA98";
    --icon-zipline: "\EA99";
    
}